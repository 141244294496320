import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ModelCard = ({ model }) => {
  return (
    <div className="card bg-secondary text-light h-100 shadow-sm">
      <img 
        src={model.image_url} 
        alt={model.username} 
        className="card-img-top" 
        style={{ objectFit: 'cover', height: '250px' }}
      />
      <div className="card-body d-flex flex-column">
        <h5 className="card-title text-center">{model.username}</h5>
        <p className="text-center">
          {model.onlyfansUrl && (
            <a 
              href={model.onlyfansUrl} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="btn btn-outline-primary btn-sm m-1">
              OnlyFans
            </a>
          )}
          {model.instagramUrl && (
            <a 
              href={model.instagramUrl} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="btn btn-outline-danger btn-sm m-1">
              Instagram
            </a>
          )}
          {model.fanslyUrl && (
            <a 
              href={model.fanslyUrl} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="btn btn-outline-info btn-sm m-1">
              Fansly
            </a>
          )}
        </p>
      </div>
    </div>
  );
};

export default ModelCard;
