import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ModelCard from './components/ModelCard';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  const [models, setModels] = useState([]);

  useEffect(() => {
    // Fetch model data from the API
    axios.get('http://37.228.129.105:3001/api/models')
      .then(response => {
        setModels(response.data);
      })
      .catch(error => {
        console.error('Error fetching model data:', error);
      });
  }, []);

  return (
    <div className="container-fluid bg-dark text-light min-vh-100">
      <header className="text-center py-4">
        <h1 className="display-4">Top-Rated Models</h1>
        <p className="lead">Discover and rank popular models</p>
      </header>
      <div className="container">
        <div className="row">
          {models.map(model => (
            <div className="col-md-4 mb-4" key={model.id}>
              <ModelCard model={model} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;
